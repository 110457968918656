import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.trim.js";
import { mapState, mapActions } from "vuex";
import Mixin from "@/utils/mixin";
import { CheckerApi } from "@/api";
import { TreeSelect } from "ant-design-vue";
var SHOW_ALL = TreeSelect.SHOW_ALL;
export default {
  name: "checkerAdd",
  components: {},
  data: function data() {
    var _this = this;
    var usernameCheck = function usernameCheck(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入鉴定师名称"));
      }
      callback();
    };
    var nicknameCheck = function nicknameCheck(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入账户名称"));
      }
      callback();
    };
    var passwordCheck = function passwordCheck(rule, value, callback) {
      if (_this.editId && !value) {
        callback();
      }
      if (!value) {
        return callback(new Error("请输入账户密码"));
      }
      if (value) {
        var PWDREG = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,18}$/;
        var CHNREG = /[\u4E00-\u9FA5]/g;
        if (CHNREG.test(value)) {
          return callback(new Error("不可输入中文"));
        }
        if (!PWDREG.test(value)) {
          return callback(new Error("必须包含大小写字母和数字的组合，可以使用特殊字符，长度在6-18之间"));
        }
      }
      callback();
    };
    var paytypeCheck = function paytypeCheck(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入结算方式"));
      }
      callback();
    };
    var paynumberCheck = function paynumberCheck(rule, value, callback) {
      if (!value) {
        return callback(new Error("请输入结算账户"));
      }
      callback();
    };
    return {
      maxTagCount: 1,
      selectBrandIdx: 0,
      selectCategoryIdx: 0,
      selectList: [],
      brand: "",
      pickedCBList: [],
      ruleForm: {
        fastcheck: 0,
        username: "",
        nickname: "",
        password: "",
        onlinetimebegin: "",
        onlinetimeend: "",
        paytype: "",
        paynumber: ""
      },
      rules: {
        username: [{
          validator: usernameCheck,
          trigger: "change"
        }],
        nickname: [{
          validator: nicknameCheck,
          trigger: "change"
        }],
        password: [{
          validator: passwordCheck,
          trigger: "change"
        }],
        paytype: [{
          validator: paytypeCheck,
          trigger: "change"
        }],
        paynumber: [{
          validator: paynumberCheck,
          trigger: "change"
        }]
      },
      form: this.$form.createForm(this, {
        name: "dynamic_rule"
      }),
      replaceFields: {
        children: "children",
        title: "name",
        key: "id",
        value: "id"
      },
      SHOW_ALL: SHOW_ALL
    };
  },
  mixins: [Mixin],
  computed: _objectSpread(_objectSpread({}, mapState("base", ["brandList", "categoryList", "categoryBrandList"])), {}, {
    editId: function editId() {
      return this.$route.query.id;
    } // checkBC(){
    //     let categoryBrandBox = []
    //     this.pickedCBList.forEach((it,idx)=>{
    //         this.categoryBrandList.forEach((item,index)=>{
    //             if(it==item.id){
    //                 categoryBrandBox.push(item)
    //             }
    //         })
    //     })
    //     return categoryBrandBox
    // }
  }),
  created: function created() {
    this.getCategoryBrand();
    if (this.editId) {
      this.getCheckerDetail();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions("base", ['getCategoryBrand'])), {}, {
    handleChange: function handleChange(val) {},
    handledelete: function handledelete(val) {
      // console.log('val', val.id)
      // this.pickedCBList = this.pickedCBList.filter(it=>it!=val.id)
      // console.log('pickedCBList', this.pickedCBList);
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getCheckerDetail: function getCheckerDetail() {
      var _this2 = this;
      CheckerApi.checkerDetail({
        id: this.editId
      }).then(function (res) {
        _this2.ruleForm = _objectSpread({}, res);
        _this2.pickedCBList = [];
        var obj = {};
        res.checkranges = res.checkranges.reduce(function (cur, next) {
          obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
          return cur;
        }, []);
        res.checkranges.forEach(function (it) {
          _this2.pickedCBList.push(it.id);
        });
        delete _this2.ruleForm.checkranges;
        _this2.ruleForm.onlinetimebegin = _this2.$moment(res.onlinetimebegin, "HH:mm");
        _this2.ruleForm.onlinetimeend = _this2.$moment(res.onlinetimeend, "HH:mm");
      }).catch(function () {});
    },
    valTrim: function valTrim(e) {
      e.target.value = e.target.value.trim();
      return e;
    },
    handleAdd: function handleAdd(val) {
      var _this3 = this;
      if (val) {
        this.$refs["ruleForm"].resetFields();
        this.$router.push("/check/user");
        return;
      }
      if (!this.pickedCBList.length) {
        return this.toast("请选择鉴定范围", "error");
      }
      this.$refs["ruleForm"].validate(function (valid) {
        if (valid) {
          console.log('ruleForm', _this3.ruleForm);
          var params = Object.assign({}, _this3.ruleForm, {
            checkrangeids: _this3.pickedCBList
          });
          params.onlinetimebegin = params.onlinetimebegin.format("HH:mm");
          params.onlinetimeend = params.onlinetimeend.format("HH:mm");
          if (params.onlinetimebegin == "00:00" || params.onlinetimeend == "00:00") {
            _this3.toast("在线时间请勿选择 00:00 ", "error");
            return;
          }
          if (params.onlinetimebegin == params.onlinetimeend) {
            _this3.toast("在线时间起始与结束时间不可相等", "error");
            return;
          }
          if (!_this3.editId) {
            CheckerApi.save(params).then(function (res) {
              _this3.$router.push("/check/user?addType=1");
            }).catch(function (err) {});
          } else {
            CheckerApi.checkerUpdate(_objectSpread(_objectSpread({}, params), {}, {
              id: _this3.editId
            })).then(function (res) {
              _this3.$router.push("/check/user");
            }).catch(function (err) {});
          }
        } else {
          return false;
        }
      });
    }
  })
};