import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form-model", {
    ref: "ruleForm",
    attrs: {
      model: _vm.ruleForm,
      rules: _vm.rules,
      labelCol: {
        style: "min-width: 100px"
      },
      form: _vm.form
    }
  }, [_c("a-form-model-item", {
    attrs: {
      prop: "nickname",
      label: "鉴定师昵称"
    }
  }, [_c("a-input", {
    attrs: {
      size: "large",
      placeholder: "请输入英文"
    },
    on: {
      change: function change(e) {
        return _vm.valTrim(e);
      }
    },
    model: {
      value: _vm.ruleForm.nickname,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "nickname", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.nickname"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      prop: "username",
      label: "账户名称"
    }
  }, [_c("a-input", {
    attrs: {
      size: "large",
      placeholder: "请输入"
    },
    on: {
      change: function change(e) {
        return _vm.valTrim(e);
      }
    },
    model: {
      value: _vm.ruleForm.username,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "username", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.username"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      prop: "password",
      label: "账户密码"
    }
  }, [_c("a-input", {
    attrs: {
      size: "large",
      placeholder: "字母大小写和数字组合，6～18位",
      "max-length": 18
    },
    on: {
      change: function change(e) {
        return _vm.valTrim(e);
      }
    },
    model: {
      value: _vm.ruleForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "password", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.password"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "可鉴定范围"
    }
  }, [_c("a-select", {
    staticStyle: {
      width: "262px"
    },
    attrs: {
      mode: "multiple",
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择",
      "option-label-prop": "label"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.pickedCBList,
      callback: function callback($$v) {
        _vm.pickedCBList = $$v;
      },
      expression: "pickedCBList"
    }
  }, _vm._l(_vm.categoryBrandList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-model-item", {
    attrs: {
      prop: "fastcheck",
      label: "快速鉴定"
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.ruleForm.fastcheck,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "fastcheck", $$v);
      },
      expression: "ruleForm.fastcheck"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 支持 ")]), _c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 不支持 ")])], 1)], 1), _c("a-form-model-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "在线时间"
    }
  }, [_c("div", {
    staticClass: "time_choose"
  }, [_c("a-form-model-item", {
    staticClass: "search-item-long",
    attrs: {
      prop: "onlinetimebegin"
    }
  }, [_c("a-time-picker", {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      size: "large",
      format: "HH:mm"
    },
    model: {
      value: _vm.ruleForm.onlinetimebegin,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "onlinetimebegin", $$v);
      },
      expression: "ruleForm.onlinetimebegin"
    }
  })], 1), _c("a-form-model-item", {
    staticClass: "search-item-long",
    attrs: {
      prop: "onlinetimeend"
    }
  }, [_c("a-time-picker", {
    attrs: {
      size: "large",
      format: "HH:mm"
    },
    model: {
      value: _vm.ruleForm.onlinetimeend,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "onlinetimeend", $$v);
      },
      expression: "ruleForm.onlinetimeend"
    }
  })], 1)], 1)]), _c("a-form-model-item", {
    attrs: {
      prop: "paytype",
      label: "结算方式"
    }
  }, [_c("a-input", {
    attrs: {
      size: "large",
      placeholder: "请输入"
    },
    on: {
      change: function change(e) {
        return _vm.valTrim(e);
      }
    },
    model: {
      value: _vm.ruleForm.paytype,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "paytype", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.paytype"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      prop: "paynumber",
      label: "结算账户"
    }
  }, [_c("a-input", {
    attrs: {
      size: "large",
      placeholder: "请输入"
    },
    on: {
      change: function change(e) {
        return _vm.valTrim(e);
      }
    },
    model: {
      value: _vm.ruleForm.paynumber,
      callback: function callback($$v) {
        _vm.$set(_vm.ruleForm, "paynumber", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "ruleForm.paynumber"
    }
  })], 1)], 1), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    staticClass: "btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };